import catalogStyles from '../sass/catalog.scss';
let commons = require('./utils/commons');
import mobileFilterMenuJS from './includes/mobile_filter_menu.js';
import detailGalleryJS from './includes/detail_gallery.js';
import catalogItem1IMG from '../img/catalog-item-1.png';
import catalogItem2IMG from '../img/catalog-item-2.png';
import catalogItem3IMG from '../img/catalog-item-3.png';
import catalogItem4IMG from '../img/catalog-item-4.png';
import catalogItem5IMG from '../img/catalog-item-5.png';
import catalogItem6IMG from '../img/catalog-item-6.png';
import detailItem1IMG from '../img/detail-item-1.png';
import detailItem2IMG from '../img/detail-item-2.png';
import detailItem3IMG from '../img/detail-item-3.png';
import detailItem4IMG from '../img/detail-item-4.png';
import detailItem5IMG from '../img/detail-item-5.png';
import detailItem6IMG from '../img/detail-item-6.png';
import wrapping1IMG from '../img/wrapping-1.png';
import wrapping2IMG from '../img/wrapping-2.png';
import wrapping3IMG from '../img/wrapping-3.png';
import cardboardIMG from '../img/cardboard.png';
