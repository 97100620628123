(function( $ ) {

    $.fn.detailGallery = function(configObj) {

        var config = {
            previewCount: 4,
            ...configObj
        }

        var initConnectedSliders = function(main, preview, isFullPage) {
            var mainItems = main.find('.main-image');
            var previewItems = preview.find('.preview-images');

            if (!mainItems || !previewItems) {
                return console.error('Not found "main-image" or "preview-images" blocks in ', main, preview)
            }

            $(mainItems).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: true,
                asNavFor: $(previewItems),
                nextArrow: main.find('.next'),
                prevArrow: main.find('.prev'),
                appendDots: main.find('.nav'),
                dots: false,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            dots: false
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            dots: false
                        }
                    },
                ]
            });

            $(previewItems).slick({
        		slidesToShow: config.previewCount,
        		slidesToScroll: 1,
        		asNavFor: $(mainItems),
        		dots: false,
                arrows: true,
                nextArrow: preview.find('.next'),
                prevArrow: preview.find('.prev'),
        		focusOnSelect: true,
                infinite: false,
        		responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 4,
                            arrows: true,
                            dots: false
                        }
        			},
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            arrows: false,
                            dots: false
                        }
        			},
            	]
        	});
        }


        return this.each(function() {
            var jqSliderMainContainer = $(this);
            var mainImageContainer = jqSliderMainContainer.find('.main-image-slider');
            var previewImageContainer = jqSliderMainContainer.find('.preview-image-slider');
            var isFullPage = jqSliderMainContainer.hasClass('full-page');

            if (!mainImageContainer || !previewImageContainer) {
                return console.error('Not found "main-image-slider" or "preview-image-slider" blocks in ', jqSliderMainContainer)
            }

            initConnectedSliders(mainImageContainer, previewImageContainer, isFullPage);

        });

    };

}( jQuery ));

$(document).ready(function() {
    $('.connected-gallery.detail').detailGallery();
});
