(function( $ ) {

    $.fn.mobileFilterMenu = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            const jqContainer = $(this);
            const filterBlocks = jqContainer.find('.filter-block');
            filterBlocks.each(function() {
                const jqfilterBLock = $(this);
                const filterOpenLink = jqfilterBLock.find('.filter-block-heading>a')[0];
                if (!filterOpenLink) {
                    console.error('mobileFilterMenu: filterOpenLink not found in ', this);
                    return false;
                }
                const jqFilterOpenLink = $(filterOpenLink);
                const closedHeight = jqFilterOpenLink.innerHeight();
                jqfilterBLock.height(closedHeight);
                if (jqfilterBLock.hasClass('open')) {
                    jqfilterBLock.removeClass('open');
                }
                jqFilterOpenLink.click(function(e) {
                    e.preventDefault();
                    const filterBlockContentHolder = jqfilterBLock.find('.filter-block-content-holder')[0];
                    if (!filterBlockContentHolder) {
                        console.error('mobileFilterMenu: filterBlockContentHolder not found in ', this);
                        return false;
                    }
                    const jqFilterBlockContentHolder = $(filterBlockContentHolder);
                    const openHeight = jqFilterBlockContentHolder.innerHeight() + closedHeight;
                    if (jqfilterBLock.hasClass('open')) {
                        jqfilterBLock.height(closedHeight);
                        jqfilterBLock.removeClass('open');
                    } else {
                        jqfilterBLock.height(openHeight);
                        jqfilterBLock.addClass('open');
                    }
                })
            })
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-filter').mobileFilterMenu();
});
